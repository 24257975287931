ion-card-content.recover-card {
  max-width: 80vh;
  width: fit-content;
  height: 100vh;
  margin: auto;
}

ion-title.recover-title {
  color: cornflowerblue;
  text-align: left;
}
