ion-card-content.about-card {
  text-align:center;
  width: 55vh;
  font-family: 'Kiwi Maru', serif;
  font-size: 1.1em;
  color:rgb(31, 30, 30);
}

ion-card.the-about-card {
  padding-left: 10px;
  padding-right: 10px;
  height: 100vh;
  overflow: scroll;
}

.bold-text {
  font-size: 1.3em;
}

.white-text {
  color: white;
}
/* width */
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
} 

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

ion-title.title {
  color: cornflowerblue;
  text-align: left;
}
.top {
  margin-bottom: 5px;
  padding-right: 8px;
  padding-left: 8px;
}

.middle {
  padding-top: 10px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 10px;
}

.bottom {
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

.drop {  
  margin-top: 2px;
  width: 22vh;
  height: 22vh;
  border-radius: 60%;
  margin-bottom: 8px;
}

.img-div {
  text-align: center;
}