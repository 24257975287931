ion-title.dashboard-title {
  color: cornflowerblue;
  /* text-align: left;
  padding-left: 4.5ch; */
}

ion-card.dashboard-card {
  /* border: none;
  padding: none; */
  margin: auto;
  text-align: center;
  padding-left: 30px;
  height: 107.5vh;
  width: 51.5vh;
}

ion-card-title.water-allocation {
  margin-top: 10px;
  text-align: center;
  font-size: 40;
}

ion-card-title.allo-title {
  margin-top: 10px;
  text-align: center;
  font-size: medium;
}
.usage-to-date {
  margin-top: -1px;
  margin-bottom: -10px;
}

ion-card.allo {
  margin-top: -25px;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: -5px;
  text-align: left;
  font-size: large;
  width:100%;
  border: 2px solid black;
}

ion-card-title.allo-title {
  font-size: 1.4rem;
}

ion-card-subtitle.curr-allo {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 0.83rem;
}

ion-card-subtitle.allo-used {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

ion-card-subtitle.allo-comparison {
  margin-left: 10px;
  margin-top: 5px;
  font-size: 0.9rem;
}
/* width */
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
} 

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

ion-card-subtitle.under-allo-title {
  font-size: 0.9rem;
  text-align: center;
  color: black;
}

ion-card-subtitle.baths {
  margin-bottom: 8px;
  margin-left: 10px;
  font-size: 0.9rem;
}

ion-card-subtitle.curr-litres {
  font-size: 0.9rem;
}
ion-card-content.content-card {
  text-align: center;
  max-width: 80vh;
  /* width: 45vw; */
  height: 92vh;
  margin: auto;
  max-height: 87vmax;
  padding-left: 10px;
}
ion-title {
  text-size-adjust: inherit;
}

.tip-link {
  margin-top: 5px;
  padding: 10px 0px ;

}
ion-button.stat-btn {
  font-size: smaller;
}

ion-slides {
  height: 100%;
  width: 100%;
  border: 0px;
}

.popover-info {
  margin: 5px;
}

.popover-content {
  text-align: center;
}

.popover-title {
  padding-top: 5px;
}

