.link {
  padding-left: 5px;
  padding-top: 5px;
}
ion-card-content.change-password-card {
  max-width: 80vh;
  width: 40vh;
  height: 100vh;
  margin: auto;
}

ion-card.ch-pss-card {
  padding-left: 10px;
}

ion-title.change-password-title {
  color: cornflowerblue;
  text-align: center;
}

.blue-text {
  font-size: medium;
  padding-top: 10px;
}

ion-item.ion-input {
  --highlight-height: 0;
  --ion-color: #ff4961;
}

ion-button.change-the-password {
  padding-top: 15px;
  
  width: 40vh;
  height: 7vh;
}

