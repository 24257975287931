  ion-card-content {
     display: table-row;
     border: 3.5px solid rgb(158, 154, 154)
 }

  ion-text.user-inititals {
    color: blue;
    font-weight: bolder;
  }
  ion-item {
    text-align: left;
    margin-right: 10px;
  }
  
  ion-avatar.sedona{
    border: 3.5px solid #fff;
  }
  ion-card img {
    object-fit: cover;
    position: relative;
    background: transparent;
    margin-top: -5%;
    margin-bottom: -2em;
    z-index: 0;
    width: 100%;
    border: 3.5px solid #fff;
  
  }
  
  .profile {
    z-index: 0;
  }
  .sedona {
    z-index: 1;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    object-fit: cover; /* Scale the image - Center and crop image */
    object-position: top;
    margin-left: 20px;
    margin-top: -7%;
    border: 3.5px solid #fff;
    
  }
  
  ion-title.title {
    color: cornflowerblue;
    text-align: left;
  }
  
  .btn {
    align-items: right
  }
  
  .details {
    padding-top: 10px;
    align-self: start;
  }
  
  .top-header {
  margin-top: 5px;
  margin-bottom: 15px;
  } 

  