ion-title.daily-title {
  color: cornflowerblue;
  text-align: left;
  padding-left: 4.5ch;
}

.link {
  padding-left: 5px;
  padding-top: 5px;
}
ion-card.daily-card {
  /* max-width: 80vh;
  width: 42vh;
  height: 90vh;
  margin: auto;
  text-align: center; */
  margin: auto;
  text-align: center;
}
ion-card-content.daily-graph {
  text-align: center;
  /* max-width: 60vh;
  /* width: 45vw; */
  /* height: 90vh;
  margin: auto;
  max-height: 87vmax; */ 
}

ion-card.morning {
  margin-top: 10px;
  margin-left: 10px;
}

ion-card-content.morning-content {
  margin-top: 10px;
  margin-left: 10px;
}