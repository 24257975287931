.check-box {
  color: blue;
  padding-left: 2px;
  padding-top: 2px;
}

.link {
  margin-left: 10px;
  padding-top: 40px;
}

ion-button {
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
}


ion-title.title {
  color: cornflowerblue;
  display: grid;
  place-items: center;
}

ion-img.logo {
  padding-top: 50px;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

ion-img.landing-icon{
  width: 40px;
  height: 40px;
  margin-left: 50px;
}

ion-content.home-content {
  padding-top: 10px;
  
}

ion-button.home-login-btn {
  padding-right: 5px;
  font-size: larger;
  width: 50vh;
  
}

ion-card.card-home {
  height: 90vh;
  width: 51.5vh;
  place-items: center;
}

.blank-space {
  color: white;
}
ion-card-content.home-card {
  
}

.center-stuff {
  text-align: center;
  display:grid;
  place-items: center;
 
}