.tick line {
    stroke: #C0C0BB
}

.tick text {
    fill: #8E8883
}

.left-axis {
    fill: #635F5D;
    font-family: 'Kiwi Maru', serif;
}

.mark {
    fill: #137B80;
}


.marks path {
    stroke: #137B80;
    stroke-width: 5;
    stroke-linejoin: round; /* rounds the joins between data points */
    stroke-linecap: round; /* rounds off the ends */
    /* overflow-x:scroll; */
}

.marks circle {
    fill: #137B80;
}

.top-label {
    margin-top: 25px;
    font-family: 'Kiwi Maru', serif;
    font-size: 2em;
    fill: #8E8883;
}

.middle-label {
    font-family: 'Kiwi Maru', serif;
    font-size: 1.3em;
    fill: #635F5D;
}

.bottom-label {
    font-family: 'Kiwi Maru', serif;
    font-size: 1.3em;
    fill: #635F5D;
}

.card {
    /* width: 100%; 
    overflow-x: scroll;  */
    padding-left: 1px;
}
/* .ion-infinite-scroll {
    /* width: 100%; 
    overflow-x: scroll;  */

.loading-data {
    margin-top: 10px;
    margin-left: 120px;
    font-size: 1.5em;
    margin-bottom: 15px;
}
.svg-element {
    /* border: 2px solid black; */
    border-radius: 10%;
    margin-top: 5px;
}

.switch-to {
    margin: 0;
    position: static;              
    top: 50%;                         
    transform: translate(0, -50%)
}

.day-btn {
    margin-top: 5px;
    margin-right: 3px;
    margin-bottom: -5px;
}

.night-btn {
    margin-top: 5px;
    margin-left: 3px;
    margin-bottom: -5px;
}

.morn {
    margin-top: -5px;
    margin-bottom: 5px;
}

.after-evening {
    margin-top: -5px;
    margin-bottom: 5px
}

.green-text {
    color: rgb(7, 212, 7);
}

.aug-total {
    font-size: 1.1em;
    margin-left: -720px;
}