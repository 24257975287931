ion-card-content.settings-card {
  width: fit-content;
  height: 100vh;
  margin: auto;
}

ion-title.title {
  color: cornflowerblue;
  text-align: left;
}

.fourOfour {
 text-align: center;
 font-family: 'Kiwi Maru', serif;
}

.water {
  z-index: 0;
}