ion-card-content.register-card {
  max-width: 50vh;
  width: fit-content;
  height: 100vh;
  margin: auto;
}

ion-title.title {
  color: cornflowerblue;
  text-align: left;
  padding-left: 4.5ch;
}

/* Define the variables */
ion-item.ion-input {
  --highlight-height: 0;
}

ion-item.ion-input {
  height: calc(var(--highlight-height) * 1px);
}

.rouge {
  color: crimson;
}

.required-text {
  padding-left: 15px;
}

.card-panel {
  padding-left: 10px;
}

ion-button.reg-button {
  margin-left: -10px;
}

.eye-toggle {
  cursor: pointer;
  display:-ms-inline-grid;
  place-items:unset;
  margin-top: 30px;
}