ion-title.title{
    padding-left: 160px;
}

ion-card-title.bthTitle {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 5px;
}

ion-card-title.kthTitle {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 5px;
}

ion-card-title.gdnTitle {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 5px;
}
ion-card-content{
    display: table-row;
}

ion-list.bthlist{
    margin-left: 10px;
}

ion-list.kthlist{
    margin-left: 10px;
}

ion-list.gdnlist{
    margin-left: 10px;
}

.addSpace{
    padding-top: 5px;
    padding-bottom: 5px;
}

.last {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

ion-card.garden-card {
    height: 61vh;
    width: 52vh;
    align-items: center;
}

ion-card.kitchen-card {
    height: 61vh;
    width: 52vh;
    align-items: center;
}

ion-card.bathroom-card {
    height: 61vh;
    width: 52vh;
    align-items: center;
}

.flex-container {
    display: table-row;
    margin: auto;
  justify-content: right;
  
}