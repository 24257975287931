.link {
  padding-left: 5px;
  padding-top: 5px;
}

ion-card-content.login-card {
  width: 42vh;
  height: 100vh;
  
}

ion-title.title {
  color: cornflowerblue;
  text-align: left;
}

.blue-text {
  font-size: medium;
  padding-top: 10px;
}

/* Define the variables */
ion-item.ion-input {
  --highlight-height: 0;
}

ion-item.ion-input {
  height: calc(var(--highlight-height) * 1px);
}

ion-list.fplink {
  padding-top: 5px;
  margin-left: -20px;
  
}

ion-list.reglink {
  margin-top: 12px;
  margin-left: -20px;
  
}

ion-button.press-me {
  margin-top: 45px;
  margin-left: -20px;
  width: 47vh;
}

ion-item.topSpace {
  margin-top: 20px;
}

.login-body {
 margin-left: 35px;
 text-align: center;

}

.eye-toggle {
  margin-top: 5px;
  font-size:x-large;
  cursor: pointer;
  display: grid;
  place-items:initial;
}

.login-confirmation {
  color: #080808;
  font-size: 1.1rem;
  font-family: 'Kiwi Maru', serif;
}