.link {
  padding-left: 5px;
  padding-top: 5px;
}
ion-card-content.recoverpassword-card {
  width: 40vh;
  height: 100vh;
  margin: auto;
}

ion-title.title {
  color: cornflowerblue;
  text-align: left;
}
