ion-title.yearly-title {
    color: cornflowerblue;
    text-align: left;
    padding-left: 4.5ch;
  }
  
  .link {
    padding-left: 5px;
    padding-top: 5px;
  }
  ion-card.yearly-card {
    /* max-width: 80vh;
    width: 42vh;
    height: 90vh;
    margin: auto;
    text-align: center; */
    margin: auto;
    text-align: center;
  }
  ion-card-content.yearly-graph {
    text-align: center;
    /* max-width: 60vh;
    /* width: 45vw; */
    /* height: 90vh;
    margin: auto;
    max-height: 87vmax; */ 
  }
  
  ion-card.year {
    margin-top: 10px;
    margin-left: 10px;
  }

  ion-card-title.yearly-water-usage {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  ion-card-content.year-content {
    margin-top: 10px;
    margin-left: 10px;
  }

  .loading-spinner {
    margin-left: 10px;
    margin-top: 10px;
  }

  .season-btn {
    text-align: center;
    place-items: center;
    font-size: small;
  }

  .seasonly-btn {
    margin-left: 10px;
    font-size: small;
  }

  ion-button.bottom-btn {
    margin-top: 10px;
    font-size: small;
  }

  ion-text.foot-text {
    margin-top: 10px;
    font-size: medium;
  }

  ion-button.yearly-btn {
    font-size: small;
  }

  ion-button.year-btn {
    font-size: small;
    padding-left: 5px;
  }