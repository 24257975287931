ion-card-content.account-detail-card {
  max-width: 80vh;
  width: 70vh;
  height: 70vh;
  display: table-row;
  z-index: 0;
}

ion-textarea {
  /* border: 3.5px solid rgb(158, 154, 154); */
  background-color: orange;
}

ion-avatar.sedona{
  border: 3.5px solid #fff;
}
ion-card img {
  object-fit: cover;
  position: relative;
  z-index: 5;
  width: 30%;
  border: 3.5px solid #fff;
}


.sedona {
  z-index: 1;
  border-radius: 50%;
  object-fit: cover; /* Scale the image - Center and crop image */
  object-position: top;
  margin-left: 20px;
  margin-top: -7%;
  border: 3.5px solid #fff;
  margin-bottom: -1em;
  
}

ion-title.title {
  color: cornflowerblue;
  padding-left: 80px;
}

.btn {
  align-items: right
}

.blue-text {
  color: blue;
  text-align: center;
  font-size: medium;
  padding-top: 10px;
  font-size: 0.2rem;
}

/* .intial-details {
  margin-top: -10px;
} */

.details {
  padding-top: 10px;
}

ion-item.initial-details {
  z-index: 0;
}
